// this adds the little white gradient to Most Picked section on the front page
jQuery(function ($) {
    $(document).ready(function() {
        function setTabs() {
            var tabs = $('#home-picked-most').find('.hover-tab__content');
            tabs.each(function(){
                var imgUrl = $(this).css('background').match(/"([^"]+)"/)[1];
                var gradientString = 'linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,0) 40%), url('+imgUrl+') center / cover no-repeat';
                var gradientStringTablet = 'linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 55%), url('+imgUrl+') 50% 50px / cover no-repeat'
                var gradientStringMob = 'linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.9) 35%, rgba(255,255,255,0) 50%), url('+imgUrl+') 70% 40px / cover no-repeat';
                var windowSize = $(window).width();
                if (windowSize > 1023) {
                    $(this).attr('style', 'background: ' + gradientString + ' !important');
                }
                else if (windowSize > 601) {
                    $(this).attr('style', 'background: ' + gradientStringTablet + ' !important');
                }else {
                    $(this).attr('style', '');
                }
            });
        }

        $(window).on('resize orientationchange', function() {
            setTabs();
        });

        setTabs();
    }); 
});