// COMMENTED THIS OUT, NOT SURE WHERE IT IS BETING USED JUST YET, THIS WAS BREAKING MY NAV SCROLL TO COMPONENT

// jQuery(function($){
//   $('a[href*="#"]')
//   .not('[href="#"]')
//   .not('[href="#0"]')
//   .click(function (event) {
//     if (
//       location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
//       // Image Carousel uses # tags to scroll through images, smooth scroll was interfering with this functionality
//       location.hostname == this.hostname && location.pathname != '/about/our-story/'
//     ) {
//       var target = $(this.hash);
//       var headerHeight = $('.site-header').height();
//       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//       if (target.length) {
//         event.preventDefault();
//         $('html, body').animate({
//           scrollTop: target.offset().top - headerHeight
//         }, 1000, function () {
//           var $target = $(target);
//           $target.focus();
//           if ($target.is(":focus")) { //
//             return false;
//           } else {
//             $target.attr('tabindex', '-1');  
//             $target.focus();
//           };
//         });
//       }
//     }
//   })
// });

