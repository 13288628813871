jQuery(function($){
  $('.our-story__slick .col-12').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  });

  $('.fruit-picker__container').slick({
    slidesToShow: 1,
    arrows: true,
    infinite: true,
    fade: true,
    speed: 500
  });

  $('a[data-slide]').click(function (e) {
    e.preventDefault();
    var slideno = $(this).data('slide');
    $('.fruit-picker__container').slick('slickGoTo', slideno);
  });
  
  var fruitIcons = $('.fruit-picker__icon');
  $(fruitIcons[0]).addClass('active');
  var lastIndex = $(fruitIcons[0]);

  $('.fruit-picker__container').on('afterChange', function(event, currentSlide){
    var slideIndex = currentSlide.currentSlide;
    $(lastIndex).removeClass('active');
    lastIndex = fruitIcons[slideIndex];
    $(fruitIcons[slideIndex]).addClass('active');
  })

  // slick on product page

  $('.product__hero__slider--content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.product__hero__slider--nav'
  });
  
  $('.product__hero__slider--nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    asNavFor: '.product__hero__slider--content',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    vertical: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          vertical: false,
          centerMode: false,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          vertical: false,
          centerMode: false,								
        }
      }
    ]
  });


  // Nav Slick
  var navSlick = $('.nav-recipes ul.sub-menu');

  function initializeSlick() {
    $('.nav-recipes ul.sub-menu li.mobile-only').remove();
    navSlick.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      centerMode: true
    });
  }
  
  function myArrows() {
    $(".slick-prev").text("<"); 
    $(".slick-next").text(">"); 
  }

  if ($(window).width() >= 1024) {
    initializeSlick();
    myArrows();
  } 

  $(window).resize(function(){
    navSlick[0].slick.refresh();
    myArrows();
  });

  

});