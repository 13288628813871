jQuery(function($) {
	
	$(document).ready(function() {
		
		$('.fruit-in-jar--right').slick();
		
		var quoteSlider = $('.home-quotes > .main > .col-12');
		var next = quoteSlider.find('.slick-next');
		var prev = quoteSlider.find('.slick-prev');

		quoteSlider.on('init', function(event, slick, currentSlide, nextSlide){
			console.log('fire');
			var next = quoteSlider.find('.slick-next');
			var prev = quoteSlider.find('.slick-prev');
			var container = '<span class="slick-btn-container"></span>';
			var slider = quoteSlider.find('.slick-slide');
			quoteSlider.find('.slick-slide').append(container);
				container = quoteSlider.find('.slick-slide .slick-btn-container');
				next.appendTo(container);
				prev.appendTo(container);
				slider.find('p').fadeOut();
				setNextBackHeight(this, 'init');
		});

		quoteSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
			setNextBackHeight(this, 'afterChange');
		});

		quoteSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var current = quoteSlider.find('.slick-current');
			current.find('p').fadeOut();
		});

		$(window).on('resize orientationchange', function() {
			quoteSlider.slick('resize');
		});


		function setNextBackHeight(_this, _event) {
			var height = $(_this).find('.slick-current div div').height() + 130;
			var slider = quoteSlider.find('.slick-slide');
			var current = quoteSlider.find('.slick-current');
		

			slider.stop().fadeOut( 'fast', function() {
				slider.height(height);
				current.css({ 'height': 'auto' });
				slider.fadeIn( 'fast', function(){
					current.find('p').fadeIn();
				} );
			});
		}

		quoteSlider.slick({fade: true});

	}); 

});
