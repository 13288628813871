jQuery(function($) {

	$('.site-header .nav-toggle').click(function(){
		if ( $('.site-header').hasClass('expanded') ) {
			$(this).removeClass('expanded');
			$('.site-header').removeClass('expanded');
		} else {
			$(this).addClass('expanded');
			$('.site-header').addClass('expanded');
		}
	});
	
	$('.site-header .dropdown a').click(function(e){
		if ( $(window).width() < 1024 ) {
			if($(this).next().hasClass('sub-menu')) {
				e.preventDefault();
			}
			$(this).parent().toggleClass('expanded');
		}
	});

	$('.site-header .dropdown').each(function( index, item ) {
		var submenu = $(item).find('.sub-menu:first');
		var a = $(item).find('a:first');
		var attr = a.attr('href');
		
		if (typeof attr !== typeof undefined && attr !== false) {
			
			a.clone().prependTo( submenu ).wrap('<li class="mobile-only menu-item"></li>');
		}

	});

	$(window).on('scroll', function(){
		if($(window).scrollTop() >= $('#header').height()){
			$('.site-header').addClass('blue');
		}else{
			$('.site-header').removeClass('blue');
		}
	})
});