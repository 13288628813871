// Automatically checks off the 'Quality Issue' checkbox 
// if users visit 'contact-us/?issue=quality'
jQuery(function ($) {
    if ($('.page-contact-us').length) {
        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };
        var issue = getUrlParameter('issue');
        if (issue === 'quality') {
            $('input[type=radio][value="Quality Issue"]').prop("checked", "true");
        }
    }
});