jQuery(function($) {
	function revealOnHover() {

		var tabId = $(this).attr('data-content'),
			tabContainer = $(this).closest('.vertical-tabs'),
			tabContent = $('#' + tabId);

		tabContainer.find('.hover-tab').removeClass('active');
		$(this).addClass('active');
		tabContainer.find('.tab-content').removeClass('active');
		tabContent.addClass('active');
	}

	$('.hover-tab').mouseenter(revealOnHover);

	$('.vertical-tabs').each(function() {
		$(this).find('.hover-tab').first().trigger('mouseenter');
	});
});
